<template>
  <div class="news">
    <div class="content-news">
      <div class="content-news-itens container">
        <div class="content-news-itens-header">
          <h1 class="title" v-html="$options.filters.removeP($t('vehicle_new_details.title_page_news'))"></h1>
        </div>
        <div class="content-news-itens-body">
          <template v-if="loading">
             <div class="row">
               <div v-for="n in 10" :key="n" class="col-sm-3">
                 <PuSkeleton
                  height="200px"                      
                  class="content-banner-itens-image"/>
               </div>
             </div>
          </template>
          <div v-else v-for="(category, index) in categories" 
                  :key="index" class="content-elements">
            <div class="content-elements-header">
              <h2>{{ category.name }}</h2>
            </div>
            <div class="content-elements-body">
              <div class="row">
                <template v-for="vehicle in vehicles">
                <div
                  v-if="vehicle.category.slug == category.slug"
                  :key="vehicle.id"
                  class="col-12 col-md-6 col-lg-4 col-xl-3">
                  <CardNew :vehicle="vehicle" />
                </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardNew from "@/components/cards/CardNew.vue";
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "VehicleNews",
  components: {
    CardNew,
  },
  computed: {
    ...mapGetters("VehicleNew", [
      "loading",
      "vehicles",
      "categories",      
    ]),
  },
  methods: {
    ...mapActions("VehicleNew", ["loadVehicleNews"]),
  },
  mounted() {
    this.loadVehicleNews();
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss">
.content-news {
  padding: 50px 0px;
  &-itens {
    &-header {
      text-align: left;
      margin-bottom: 50px;
      @media (max-width: 991px) {
        text-align: center;
      }
      .title {
        position: relative;
        font-size: 40px;
        font-family: "Font Medium";
        color: #2f2f32;
        margin-bottom: 0;
        padding-bottom: 6px;
        @media (max-width: 991px) {
          font-size: 20px;
        }
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          height: 4px;
          width: 128px;
          display: block;
          background: var(--main-color);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          border-radius: 10px;
          @media (max-width: 991px) {
            left: 50%;
            transform: translateX(-50%);
          }
        }
        span {
          font-family: "Font Black";
          text-transform: uppercase;
        }
      }
    }
    &-body {
      .content-elements {
        margin-bottom: 50px;
        &-header {
          text-align: left;
          margin-bottom: 30px;
          h2 {
            position: relative;
            font-size: 24px;
            font-family: "Font Black";
            color: #2f2f32;
            margin-bottom: 0;
            padding-bottom: 6px;
            text-transform: uppercase;
            @media (max-width: 991px) {
              font-size: 20px;
            }
          }
        }
        &-body {
          @media (max-width: 991px) {
            .content-card-new {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
}
</style>