<template>
  <div class="content-card-new">
    <router-link tag="a" :to="{name: 'vehicleNewDetail', params: {
        slug: vehicle.slug
      }}" class="content-card-new-header">
      <h2 class="title">{{ vehicle.name }}</h2>
      <h3 class="subtitle">{{ vehicle.subtitle}}</h3>
    </router-link>
    <div class="content-card-new-body">
      <router-link tag="a" :to="{name: 'vehicleNewDetail', params: {
        slug: vehicle.slug
      }}">
        <img
          class="img-fluid"
          :src="vehicle.thumb_path"
          alt="image vehicle new"
          title="image vehicle new"
        />
      </router-link>
      <div class="content-card-new-body-btns">
        <div class="elements">
          <router-link tag="a" :to="{name: 'directSales'}" class="btn-test-drive">
            <p>AGENDAR <span>TEST DRIVE</span></p>
          </router-link>
          <button class="btn-whatsapp btn-content-whatsapp">
            <p>ATENDIMENTO <span>WHATSAPP</span></p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27.831"
              height="27.857"
              viewBox="0 0 27.831 27.857"
            >
              <path
                d="M23.718,4.045a13.923,13.923,0,0,0-19.621,0A13.666,13.666,0,0,0,0,13.8v.007A13.737,13.737,0,0,0,1.7,20.326L.038,27.857l7.618-1.733a13.944,13.944,0,0,0,6.253,1.486h.006a13.878,13.878,0,0,0,9.818-4.042,13.658,13.658,0,0,0-.014-19.523Zm-9.8,21.391h0A11.752,11.752,0,0,1,8.324,24.02l-.36-.2L2.9,24.977l1.1-5-.212-.366A11.622,11.622,0,0,1,2.174,13.8,11.737,11.737,0,0,1,22.183,5.586a11.558,11.558,0,0,1,3.473,8.224A11.7,11.7,0,0,1,13.915,25.436Zm0,0"
                transform="translate(0)"
                fill="#4ab900"
              />
              <path
                d="M141.44,141.863h-.61a1.171,1.171,0,0,0-.849.4,3.552,3.552,0,0,0-1.114,2.646,6.161,6.161,0,0,0,1.3,3.281,13.047,13.047,0,0,0,5.437,4.789c2.689,1.057,3.236.847,3.819.794a3.214,3.214,0,0,0,2.149-1.508,2.644,2.644,0,0,0,.186-1.508c-.08-.132-.292-.211-.61-.37s-1.878-.939-2.17-1.045-.5-.159-.716.159-.837,1.051-1.023,1.262-.371.238-.69.08A8.8,8.8,0,0,1,144,149.258a9.753,9.753,0,0,1-1.789-2.231c-.186-.317-.02-.489.14-.647.143-.142.336-.336.5-.521a2.144,2.144,0,0,0,.311-.529.582.582,0,0,0-.027-.556c-.08-.159-.691-1.727-.974-2.355h0C141.917,141.89,141.666,141.872,141.44,141.863Zm0,0"
                transform="translate(-131.311 -134.144)"
                fill="#4ab900"
              />
            </svg>
          </button>
        </div>
        <button class="btn-cotation" @click="toggleFormFixed()">
          <p>COTAÇÃO</p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    vehicle: Object
  },
  methods: {
    ...mapActions('Site', ['toggleFormFixed']),    
  }
};
</script>

<style lang="scss">
.content-card-new {
  display: block;
  text-decoration: none !important;
  padding: 30px;
  border: 1px solid rgba(203, 203, 203, 0.61);
  border-radius: 10px;
  margin-bottom: 60px;
  transition: 500ms;
  &:hover,
  &:focus {
    @media (min-width: 992px){
      cursor: pointer;
      border: 1px solid transparent;
      background: #edede3;
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.16);
      .content-card-new-body-btns {
        height: 120px;
        margin-top: 50px;
        overflow: visible;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px){
    padding: 30px 15px;
  }
  @media (max-width: 991px){
    background: #edede3;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    margin-bottom: 0;

  }
  &-header {
    text-align: left;
    @media (max-width: 991px){
      text-align: center;
    }
    .title {
      font-size: 26px;
      font-family: "Font Black";
      color: #3e3e3e;
      height: 65px;
      overflow: hidden;
      margin-bottom: 0;
    }
    .subtitle {
      font-size: 14px;
      font-family: "Font Regular";
      color: #3e3e3e;
      height: 34px;
      margin-bottom: 0;
    }
  }
  &-body {
    &-btns {
      margin: 0;
      overflow: hidden;
      height: 0;
      transition: 500ms;
      @media (max-width: 991px){
        height: 120px;
        margin-top: 50px;
        overflow: visible;
      }
      .elements {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn-test-drive {
          width: 125px;
          height: 40px;
          background: 0;
          border: 0;
          position: relative;
          padding: 0;
          padding-bottom: 5px;
          p {
            text-align: left;
            font-size: 12px;
            font-family: 'Font Black';
            color: var(--main-color);
            margin-bottom: 0;
            line-height: 1.2;
            span {
              display: block;
              font-size: 15px;
            }
          }
          &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 3px;
            background: var(--main-color);
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          }
        }
        .btn-whatsapp {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 125px;
          height: 40px;
          background: 0;
          border: 0;
          position: relative;
          padding: 0;
          padding-bottom: 5px;
          p {
            text-align: left;
            font-size: 12px;
            font-family: 'Font Black';
            color: var(--green);
            margin-bottom: 0;
            line-height: 1.2;
            span {
              display: block;
              font-size: 15px;
            }
          }
          &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 3px;
            background: var(--green);
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          }
        }
      }
      .btn-cotation {
        height: 63px;
        width: 100%;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--main-color-light);
        border: 1px solid var(--main-color-light);
        border-radius: 10px;
        box-shadow: 0px 3px 5px rgba(0,7,7,0.36);
        margin-top: 17px;
        transition: 500ms;
        p {
          position: relative;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-family: 'Font Medium';
          color: var(--white);
          margin-bottom: 0;
          text-transform: uppercase;
          transition: 500ms;
          &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: -1px;
            height: 4px;
            width: 100%;
            background: var(--white);
            border-radius: 10px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            transition: 500ms;
          }
        }
        &:hover,&:focus {
          cursor: pointer;
          background: var(--white);
          p {
            color: var(--main-color-light);
            &::after {
              content: '';
              background: var(--main-color-light);
            }
          }
        }
      }
    }
  }
}
</style>